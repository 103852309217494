import { render, staticRenderFns } from "./Crm.vue?vue&type=template&id=1fa88f3a&"
import script from "./Crm.vue?vue&type=script&lang=js&"
export * from "./Crm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VCol,VIcon,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1fa88f3a')) {
      api.createRecord('1fa88f3a', component.options)
    } else {
      api.reload('1fa88f3a', component.options)
    }
    module.hot.accept("./Crm.vue?vue&type=template&id=1fa88f3a&", function () {
      api.rerender('1fa88f3a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ShopSetting/Pages/Crm/Crm.vue"
export default component.exports