<template>
  <div>
    <div class="d-flex align-center">
      <header-label
        :label="$t('crm.header')"
        prepend-icon="$crmHeader" />
      <div class="mb-3">
        <v-icon color="primary">
          mdi-chevron-left
        </v-icon>
      </div>
      <span class="primary--text mb-3">
        {{ $t('shopsetting.header') }}
      </span>
    </div>
    <v-card flat>
      <v-card-text>
        <v-row
          class="mb-3"
          no-gutters>
          <v-col cols="10">
            <crm-toggle
              v-model="setting.enableCrm"
              :text="$t('crm.enableCrm')"
              :state="state.enableCrm"
              text-icon="$crmTitle"
              class="mr-12"
              @input="submit('enableCrm')">
            </crm-toggle>
          </v-col>
        </v-row>
        <p class="caption mb-0">
          {{ $t('crm.hint1') }}
        </p>
        <p class="caption mb-0">
          {{ $t('crm.hint2') }}
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import CrmToggle from '@/views/ShopSetting/Pages/Crm/components/CrmToggle'
import { GET_SETTING_CRM } from '@/resources/graphql/query/Setting'
import { UPDATE_SETTING } from '@/resources/graphql'

export default {
  name: 'Crm',
  apollo: {
    setting: () => ({
      fetchPolicy: 'no-cache',
      query: GET_SETTING_CRM
    })
  },
  components: {
    CrmToggle
  },
  data () {
    return {
      setting: {
        enableCrm: false
      },
      state: {
        enableCrm: 'ready'
      }
    }
  },
  methods: {
    async submit (field) {
      this.state[field] = 'loading'
      const res = await this.$apollo.mutate({
        mutation: UPDATE_SETTING,
        variables: {
          input: {
            [field]: this.setting[field]
          }
        }
      }).catch(() => {
        this.state[field] = 'error'
      })
      if (res) {
        const { data: { updateSetting } } = res
        if (updateSetting.errors && Object.keys(updateSetting.errors).length) {
          this.state[field] = 'error'
        } else {
          this.state[field] = 'success'
        }
      }
    }
  }
}
</script>
